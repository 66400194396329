@font-face {
  font-family: "OpenSans-Light";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/OpenSans-Light.ttf");
}

@font-face {
  font-family: "OpenSans-SemiBold";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/OpenSans-SemiBold.ttf");
}
@font-face {
  font-family: "OpenSans-Bold";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/OpenSans-Bold.ttf");
}

.NavigationBar-site-header {
  display: flex;
  justify-content: space-between;
  min-height: 80px;
  background-color: #494949;
  vertical-align: baseline;
}
.logo-part {
  width: 530px;
  flex-shrink: 1;
  margin-left: 5px;
}
@media screen and (min-width: 992px) {
  .logo-part {
    width: 530px;
    flex-shrink: 1;
    margin-left: 100px;
  }
}
.NavigationBar-site-header img {
  float: left;
  margin-top: 23px;
}

.NavigationBar-site-header .NavigationBar-header-title {
  margin: 0;
  font-size: 30.4px;
  font-family: "OpenSans-Light";
  margin-top: 15px;
  color: #fff;
  float: left;
  margin-left: 17px;
}

.NavigationBar-loginNavbar {
  background-color: #f7e91c;
  border: 0;
  border-radius: 0;
  vertical-align: baseline;
  text-align: left;
  height: 32px;
}
.NavigationBar-loginNavbar .NavigationBar-navbar-links {
  margin-left: 5px;
  padding: 2px 0;
}
@media screen and (min-width: 992px) {
  .NavigationBar-loginNavbar .NavigationBar-navbar-links {
    margin-left: 100px;
  }
}
.NavigationBar-loginNavbar .active {
  background: #494949;
  color: white;
  text-decoration: none;
}
.NavigationBar-loginNavbar .link:hover {
  background: #494949;
  color: white;
  text-decoration: none;
}

.NavigationBar-loginNavbar .link {
  font-family: "OpenSans-Bold";
  padding: 7px 20px;
  /* margin-right: 15px; */
  font-size: 14px;
  /* width: 110px; */
  letter-spacing: 0.1px;
  font-weight: 700;
  text-transform: uppercase;
}

.NavigationBar-loginNavbar .link i {
  margin-right: 5px;
  margin-top: 5px;
  font-size: 1rem !important;
  font-weight: bolder;
}

.login-icon {
  font-size: large;
  font-weight: bolder !important;
  margin-right: 10px;
}

.right-bar {
  display: none;
}
@media screen and (min-width: 992px) {
  .right-bar {
    flex-shrink: 0;
    float: right;
    display: flex;
    width: auto;
    height: 80px;
    color: #eee;
    padding: 10px;
  }
}
.right-bar .notif-area  {
  margin-right: 10px;
}
.right-bar .notif-area button {
  border-radius: 50%;
  background-color: #77777755;
  float: right;
  color: #eee;
  padding: 14px;
  border: 0px;
  box-shadow: none;
  text-align: center;
  width: 50px;
  height: 50px;
  margin: 5px;
}
.right-bar .notif-area button div {
  z-index: 4;
  border-radius: 50%;
  background-color: #f33;
  color: #fff;
  display: inline-flex;
  position: relative;
  top: -20px;
  right: 44px;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
}
.right-bar .notif-area button:hover,
.right-bar .notif-area button:focus {
  color: #f7e91c;
  outline: none;
  border: none !important;
  background-color: #777777;
}
.right-bar .user-area {
  float: right;
  border-left: 1px solid;
  height: 100%;
  cursor: pointer;
}
.right-bar .user-area p {
  float: left;
  color: #eee;
  margin: 10px;
  padding: 7px 2px 7px 8px;
  font-size: large;
}
.right-bar .user-area .user-image i {
  color: #494949;
  font-size: 30px !important;
}

.avatar-mini {
  width: 60px;
  height: 60px;
  border: 2px solid #ffffff;
  margin: 0px !important;
  margin-left: 10px !important;
  border-radius: 50%;
}
