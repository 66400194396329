#user-dialog {
  z-index: 1500;
  position: absolute;
  right: 50px;
  left: auto;
  top: 80px;
  border: solid 1.5px #aaa;
  width: 320px;
  height: 250px;
  background-color: #fff;
  color: #000;
  padding: 20px 5px;
}
.open {
  display: block;
}
.closed {
  display: none;
}
#user-dialog .buttons {
  bottom: 15px;
  top: auto;
  position: absolute;
  left: 10px;
  right: 10px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  border: 5px solid #ffffff;
  position: absolute;
  margin: 5px !important;
  border-radius: 50%;
  right: 50%;
  left: 50%;
  margin-left: -50px !important;
}
.user-email {
  color: #555;
  margin-top: 125px;
}
