.notification-content {
  margin-top: 80px !important;
  float: right;
  margin-right: 250px !important;
  width: auto !important;
  min-width: 0 !important;
  height: auto !important;
}

.notification-content .modal-content {
  padding-bottom: 10px;
  min-height: 0 !important;
  min-width: 600px !important;
  height: auto !important;
}
.notification-content .list-group {
  max-height: 500px;
  overflow-y: scroll;
}
.notification-content .modal-body {
  padding: 0;
  min-height: 0 !important;
  height: auto !important;
}
.notifications-header {
  font-size: 26px !important;
  height: 75px;
  padding: 15px;
  background-color: #f7e91c;
  border-bottom: 2px solid #666;
}
.notifications-header i {
  float: left;
  margin-top: 5px;
  margin-right: 10px;
  font-size: 35px !important;
}
.notifications-header p {
  float: left;
  font-weight: bold;
}
.notification-item {
  height: auto;
  margin: 3px 0;
  padding: 10px;
  cursor: pointer;
  box-sizing: border-box;
  background-color: #343a400a;
}
.unseen-date {
  color: #00a;
  font-weight: 600;
}
.notification-item:hover {
  background-color: #343a4033;
}
.notif-btns {
  text-align: right;
  margin: -8px 0;
  padding: 0;
}
.notif-btns a {
  margin: 0 5px;
  padding: 0;
}
.del-all-btn {
  margin-right: 5px;
  margin-top: 5px;
  font-size: medium !important;
  float: right;
  color: #333;
}
.notification-item .notif-btns .del {
  visibility: hidden;
}
.notification-item:hover .notif-btns .del {
  visibility: visible;
}
.del-all-btn:hover {
  color: #500;
}
.notif-btns .del:hover {
  color: #500;
}
.notif-btns .seen {
  color: #00a;
}
.notif-btns .seen:hover {
  color: #00f;
}
.notification-item-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.notification-item-right {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* flex-grow: 3; */
}
.notification-item-right div {
  display: flex;
  align-items: center;
  flex-grow: 1;
}
.notification-item-right .notif-icon {
  flex-grow: 0;
  width: 3px;
  padding: 5px;
}
.notification-item-right .notif-icon i {
  font-size: 20px !important;
  font-weight: bolder !important;
}
.notification-item-right .notif-device {
  /* width: 150px;
  flex-grow: 3; */
}
.notification-message {
  margin-left: 10px;
  margin-bottom: 5px;
}
.notification-item-top .notif-type {
  flex-grow: 1;
  font-weight: 700;
  display: flex;
  justify-content: flex-end;
}
.notification-item-top .success {
  color: #050;
}
.notification-item-top .error {
  color: #f00;
}
.notification-item-top .warning {
  color: #f00;
}
