.Archive-container {
  margin-left: 5px;
  margin-right: 5px;
  text-align: left;
  border: 0px;
}
@media screen and (min-width: 992px) {
  .Archive-container {
    margin-left: 100px;
    margin-right: 100px;
    text-align: left;
    border: 0px;
  }
}
.Archive-container .content {
  width: 100%;
  margin: 0;
  padding: 0;
  /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1); */
  background-color: #ffffff;
}
.Archive .content .title {
  margin-left: 20px;
  font-size: 1.5rem;
}
.Archive-header {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}
.Archive-header .title {
  font-size:2rem;
  margin-top: 1rem;
  font-weight: 700;
  color: #444;
}
.Archive-header .checkbox {
  font-size: 1.1rem;
  margin-left: 50px;
  margin-top: 9px;
}
.Archive-header .checkbox-text {
  font-size: 1.1rem;
  margin-left: 5px;
  margin-top: 9px;
}
.column {
  padding: 5px;
  color: #333;
  font-size: 0.75rem;
  vertical-align: middle;
  margin: 5px 3px;
  text-align: center;
  vertical-align: middle;
}
.column-header {
  font-size: 0.85rem;
  font-weight: bolder !important;
  text-align: center;
}
.column1 {
  text-align: left;
  padding: 5px;
}
.column1 .title {
  font-weight: bolder;
}
.column1 .desc {
  font-weight: normal;
}
.column2 .title {
  font-weight: bolder;
}
.column2 .desc {
  font-weight: normal;
}

.column2 {
}

.column3 {
  width: 25px;
}
.column4 {
}
.column4-label {
  vertical-align: middle;
  padding: 0;
}
.column5 {
}
.column6 {
}
.column7 {
  width: 150px;
}
i {
  margin: 0;
  font-size: 1.5rem !important;
}
td {
  vertical-align: middle;
}
.status-ok {
  color: #0e9d19;
}
.status-mid {
  color: #d0e700;
}
.status-warn {
  color: #e62525;
}
.buttons-container {
  background-color: #ffffff;
  padding: 2px;
  display: inline-block;
}
a:focus {
  color: #23527c;
  text-decoration: none;
}
#filterByStaus:hover,
#filterByStaus:focus,
#filterByStaus:active,
#filterByStaus:focus:active {
  text-decoration: none;
  border: none;
  outline: none;
}
@keyframes sensor-row-success-animation {
  0% {
    box-shadow: 0 0 0 6px #33883399;
  }
  25% {
    box-shadow: 0 0 0 6px #33883300;
  }
  50% {
    box-shadow: 0 0 0 6px #33883399;
  }
  75% {
    box-shadow: 0 0 0 6px #33883300;
  }
}
@keyframes sensor-row-error-animation {
  0% {
    box-shadow: 0 0 0 6px #ff555599;
  }
  25% {
    box-shadow: 0 0 0 6px #ff555500;
  }
  50% {
    box-shadow: 0 0 0 6px #ff555599;
  }
  100% {
    box-shadow: 0 0 0 6px #ff555500;
  }
}
@keyframes search-input {
  from {
    width: 400;
  }
  to {
    width: 750;
  }
}
