.devices-container {
  margin-left: 5px;
  margin-right: 5px;
  text-align: left;
  border: 0px;
}
@media screen and (min-width: 992px) {
  .devices-container {
    margin-left: 100px;
    margin-right: 100px;
    text-align: left;
    border: 0px;
  }
}
.devices-container .content {
  width: 100%;
  margin: 0;
  padding: 0;
  /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1); */
  background-color: #ffffff;
}
.devices .content .title {
  margin-left: 20px;
  font-size: 1.5rem;
}
.devices-header {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}
.devices-header .title {
  float: left;
  /* margin-left: 20px; */
  font-size: 2rem;
  color: #444;
}
.devices-header .checkbox {
  font-size: 1.1rem;
  float: left;
  margin-left: 50px;
  margin-top: 9px;
}
.devices-header .checkbox-text {
  font-size: 1.1rem;
  float: left;
  margin-left: 5px;
  margin-top: 9px;
}
.column {
  padding: 5px;
  color: #333;
  font-size: 0.75rem;
  vertical-align: middle;
  margin: 5px 3px;
  text-align: center;
  vertical-align: middle;
}
.column-header {
  font-size: 0.85rem;
  font-weight: bolder !important;
  text-align: center;
}
.rowStatus1 {
  padding: 5px 0;
  background-color: #e9f6ea;
}
.rowStatus2 {
  background-color: #eeeeee;
}
.rowStatus3 {
  background-color: #fdeaea;
}
.column1 {
  text-align: left;
  padding: 5px;
}
.column1 .title {
  font-weight: bolder;
}
.column1 .desc {
  font-weight: normal;
}
.column2 .title {
  font-weight: bolder;
}
.column2 .desc {
  font-weight: normal;
}

.column2 {
}

.column3 {
  width: 25px;
}
.column4 {
}
.column4-label {
  vertical-align: middle;
  padding: 0;
}
.column5 {
}
.column6 {
}
.column7 {
  width: 150px;
}
i {
  margin: 0;
  font-size: 1.5rem !important;
}
/* .buttons{
    margin: 0;
    margin-right: 8px;
    font-size: 1.5rem !important;
    background-color:0;
    color: #494949;
    width:30px;
    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.16);
} */
td {
  vertical-align: middle;
}
.status-ok {
  color: #0e9d19;
}
.status-mid {
  color: #d0e700;
}
.status-warn {
  color: #e62525;
}
.buttons-container {
  background-color: #ffffff;
  padding: 2px;
  display: inline-block;
}
a:focus {
  color: #23527c;
  text-decoration: none;
}

.device-card {
  margin: 5px 0;
}
.device-card .card-header {
  border-radius: 0 !important;
}
.device-card .card-header .left-part {
  /* margin: 5px 0; */
  display: flex;
  flex-grow: 1;
  max-width: 400px;
  justify-content: flex-start;
  align-items: center;
}
.right-part {
  display: flex;
  max-width: 750px;
  flex-grow: 3;
  justify-content: space-between;
  align-items: center;
}
.right-part .device-type p {
  padding: 1px;
  margin: 0;
}
.device-card .device-row-content {
  display: flex;
  justify-content: space-between;
  /* margin: 5px 0; */
  /* border: 1px solid #333; */
  /* border-radius: 5px; */
  height: auto;
  /* padding: 5px; */
  align-items: center;
}
.device-card .card-body {
  padding-right: 0px;
}

.left-indent {
  border-left: 1px solid #999;
  border-bottom: 1px solid #999;
  margin: 0 !important;
  margin-bottom: 0px !important;
  height: 0;
  padding: 0 !important;
  margin-left: -40px !important;
  margin-right: 10px !important;
  width: 20px;
}
