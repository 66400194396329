.chart-modal .modal-dialog {
  width: 80%!important;
  margin-top: 110px;
  /* margin: 100px !important; */
  max-width: 80% !important;
  height: auto;
}
.chartjs-render-monitor {
  max-width: 95% !important;
}
.info-modal .modal-dialog {
  width: 80% !important;
  max-width: 100% !important;
}
.info-modal .modal-body {
  min-height: 750px;
}
.create-group-modal .modal-dialog {
  width: 80% !important;
  max-width: 100% !important;
  /* margin-top: 80px; */
}
.create-group-modal .container {
  max-width: 100% !important;
}

.modal-xl  {
  margin-top: 100px;
}
.modal-xl .modal-body {
  padding-bottom: 10px;
  min-height: 600px;
}
.modal-body .container {
  margin-top: 10px;
  margin-left: 10px;
}
.chart-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  margin-left: 5px;
}
.chart-modal-header .left,
.chart-modal-header .right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.chart-modal-header p {
  font-size: 1.2rem;
  font-weight: bolder;
  padding-top: 10px;
}
.chart-modal-header .date-range {
  margin-left: 10px;
  padding: 5px;
  display: inline-block;
}
.chart-modal-footer {
  margin: 20px 0;
  text-align: center;
}
.chart-modal-footer .checkbox {
  display: inline-block;
  margin: 5px;
}
.chart-modal-footer .checkbox p {
  float: left;
}
.chart-modal-footer .checkbox i {
  float: left;
  margin-right: 10px;
}

.modal-header .close,
.modal-header .close:focus {
  outline: none;
  font-size: 30px;
}

.modal-dialog input {
  float: left;
  display: block;
}

.submit-box {
  padding: 0;
  display: flex;
}
.submit-box button {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.submit-box button i {
  font-size: 1rem !important;
  font-weight: 900 !important;
  box-sizing: border-box;
}
.form-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.form-group .form-control {
  position: relative;
  flex: 1 1 0%;
  min-width: 0;
  margin-bottom: 0;
}
.form-group .form-label {
  position: relative;
  flex: 1 1 0%;
  min-width: 100%;
  margin-bottom: 0;
}
.sectionHeader {
  font-weight: bold;
  font-size: 1.2rem;
}
.statusRowClass1 {
  background-color: #b5b5b5 !important;
}
.statusRowClass2 {
  /* border-left: 1px solid #666;
  border-right: 1px solid #666; */
  background-color: #cecece !important;
}
.statusRowClass4 {
  background-color: #eee !important;
}
.statusRowClass5 {
  background-color: #eee !important;
}

.sensorThreshold {
  width: 100%;
}
.btn-edit {
  width: 30px;
  height: 30px;
  padding: 1px !important;
  margin-left: 5px !important;
}
.btn-edit i {
  font-size: 17px !important;
}
.sensorThreshold .btn-active {
  color: #090;
}
.sensorThreshold .btn-disabled {
  color: #333;
}
.sensorThreshold .btn-warn {
  color: #e00;
}
.row2-check {
  float: left;
  margin: 0 10px 0px 0px !important;
}
.row2-check a i {
  float: left;
}
.row2-check a p {
  float: left !important;
  margin: 0 !important;
}
.column0 {
  padding: 0 !important;
  height: 100% !important;
}
.table-indent {
  border-left: 1px solid #999;
  border-bottom: 1px solid #999;
  margin: 0 !important;
  margin-bottom: 0px !important;
  height: 0;
  padding: 0 !important;
  margin-left: -35px !important;
  margin-right: 3px !important;
}
.parentSensorRow {
  background-color: #ddd;
}
.childSensorRow {
  background-color: #eee;
}
.emptyRow {
  height: 10px;
  background-color: #fff;
}
.custom-header th {
  padding: 0 !important;
}

.column-separator {
  width: 0;
  height: 100%;
  border-left: 2px solid #bbb;
}
#zoom-slider {
  writing-mode: rl-tb;
  -webkit-appearance: slider-vertical;
  width: 8px;
  height: 90px;
  padding: 0 5px;
}
.zoom-btn {
  border-radius: 50% !important;
  width: 40px;
  height: 40px;
  padding: 0 !important;
  box-shadow: none !important;
  border: 0px !important;
  margin: 5px 0;
}
.zoom-btn:hover,
.zoom-btn:focus,
.zoom-btn:active,
.zoom-btn:focus:active {
  outline: none;
  border: none !important;
  box-shadow: none !important;
}

.device-info-card {
  margin: 5px 0;
  border-radius: 0 !important;
}
.device-info-card .card-header {
  border-radius: 0 !important;
}
.row-info-content {
  display: flex;
  justify-content: space-between;
  /* margin: 5px 0; */
  /* border: 1px solid #333; */
  /* border-radius: 5px; */
  height: auto;
  /* padding: 5px; */
  align-items: center;

  width: 100%;
}

.row-info-content div {
  text-align: left;
  /* min-width: 200px;
  width: auto; */
  flex: 1 1 0;
}

.device-info-card .card-body {
  padding-right: 0px;
}

.row-left-indent {
  border-left: 1px solid #999;
  border-bottom: 1px solid #999;
  margin: 0 !important;
  margin-bottom: 0px !important;
  height: 0;
  padding: 0 !important;
  margin-left: -45px !important;
  margin-right: 10px !important;
  width: 25px;
  flex: 0 1 auto !important;
}

.row-icons-content {
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.row-icons-content div {
  width: 130px;
  text-align: center !important;
}
.row-icons-content div:nth-child(1) {
  width: 170px;
  text-align: left !important;
  flex-grow: 1;
  min-width: 290px;
}
.row-icons-content div:nth-child(2) {
  text-align: left !important;
  flex-grow: 2;
}
#dots-dropdown .dropdown-toggle::after {
  display: none !important;
}
