.Login-container {
  text-align: center;
  vertical-align: middle;
  margin-top: 100px;
  overflow-y: hidden;
  /* margin-bottom: 80px; */
}
.Login-container .content {
  text-align: center;
  padding-top: 2em;
  padding-left: 4em;
  padding-right: 4em;
  width: 50em;
  height: auto;
  border: solid 1px #ddd;
  /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1); */
  background-color: #ffffff;
}
/* .Login .content .submit{
    margin-top: 2em ;
    text-align: center;
    vertical-align: center;
} */
.Login-input {
  margin-top: 1em;
  margin-bottom: 0.5em;
}
.Login-submit {
  margin-top: 1.5em;
}
